import styled from "@emotion/styled"
import * as React from "react"

const Title = ({ en, ja }) => {
  return (
    <Wrap>
      <English>{en}</English>
      <Japanese>{ja}</Japanese>
    </Wrap>
  )
}

export default Title

const Wrap = styled('span')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginBottom: '32px'
})

const English = styled('div')({
  WebkitTextStroke: '1px #E68011',
  color: '#F9F9F9',
  fontSize: '134px',
  fontFamily: 'Roboto',
  fontFeatureSettings: '"palt" 1',
})
const Japanese = styled('div')({
  fontFeatureSettings: '"palt" 1',
  fontFamily: 'Noto Sans JP',
  color: '#161616',
  fontSize: '40px',
  marginTop: '-55px',
})