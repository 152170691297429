import * as React from "react"
import Seo from "../components/seo"
import styled from "@emotion/styled"
import Layout from "../components/layout"
import Title from "../components/feature/Contact/Title"
import { Color } from "../util/Color"
import { Link } from "gatsby"

const description = ""

const SiteMap = () => {
  return (
    <Layout>
      <Seo title="SiteMap" description={description} />
      <Title en="Site Map" ja="サイトマップ" />
      <Content>
        <Wrap>
          <Link to="/">TOP | 会社内容</Link>
        </Wrap>
        <Wrap>
          <Link to="/service">事業内容</Link>
        </Wrap>
        <Wrap>
          <Indent /> <Link to="/service">Juggle</Link>
        </Wrap>
        <Wrap>
          <Link to="https://blog.opening-line.jp/">BLOG</Link>
        </Wrap>
        <Wrap>
          <Indent />{" "}
          <Link to="https://blog.opening-line.jp/">Opening Lineのブログ</Link>
        </Wrap>
        <Wrap>
          <Link to="/contact">お問い合わせ</Link>
        </Wrap>
        <Wrap>
          <Link to="/security">情報セキュリティ基本方針</Link>
        </Wrap>
        <Wrap>
          <Link to="/privacy">プライバシーポリシー</Link>
        </Wrap>
      </Content>
    </Layout>
  )
}

export default SiteMap

const Content = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "start",
  width: "60vw",
  "> *": {
    fontWeight: 400,
    fontFeatureSettings: '"palt" 1',
    fontFamily: "Noto Sans JP",
    color: Color.black,
  },
})

const Indent = styled("div")({
  width: "16px",
  height: "1px",
})

const Wrap = styled("div")({
  display: "flex",
  margin: "4px",
})
